import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

const Dropdown = props => {
  const { dataPoint, onChange, minLabelWidth, minInputWidth } = props;

  const [selected, setSelected] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (dataPoint?.options) {
      setOptions(dataPoint.options.split(','));
    }
    if (dataPoint?.defaultValue) {
      setSelected(dataPoint.defaultValue);
    }
  }, [dataPoint]);

  return (
    <div className="flex flex-row items-center">
      <label
        htmlFor="email"
        style={{ minWidth: minLabelWidth }}
        className="block text-md text-left text-gray-900 mr-4">
        {dataPoint.label}
      </label>
      <Listbox
        value={selected}
        onChange={item => {
          setSelected(item);
          onChange(dataPoint, item);
        }}>
        {({ open }) => (
          <>
            <div className="relative mt-2">
              <Listbox.Button
                style={{ minWidth: minInputWidth }}
                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                <span className="block truncate">{selected}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options
                  style={{ minWidth: minInputWidth }}
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                  {options.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        clsx(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={clsx(
                              selected ? 'font-semibold' : 'font-normal',
                              'text-md block truncate text-left'
                            )}>
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={clsx(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Dropdown;
