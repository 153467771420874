import React, { useEffect } from 'react';

import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { useDispatch } from 'react-redux';
import { assessmentJourneyEndedAction } from '../../../../redux/actions/assessment';

const Result = props => {
  const { text, title } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    async function endPIM() {
      // clears redux data
      dispatch(assessmentJourneyEndedAction());
    }
    endPIM();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { width, height } = useWindowSize();

  return (
    <div className="flex flex-col p-8 mt-8 text-md items-center text-center w-full">
      <Confetti
        recycle={false}
        width={width}
        height={height}
        tweenDuration={7000}
      />
      <h1 className="text-3xl text-bold mx-auto">{title}</h1>
      <p className="mt-2">{text}</p>
    </div>
  );
};

export default Result;
