import React, { useEffect, useState } from 'react';

import useAssessment from '../../hooks/assessment';
import {
  EmployeeCode,
  Email,
  RateEnergies,
  Introduction,
  Result,
  FirstNameLastName,
  ChooseColleagues,
  PreInsights,
  CompletionInsights
} from './Steps';
import { ASSESSMENT_STEPS } from './Constants';
import SelectInvitation from './Steps/SelectInvitation';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../utils/LoadingSpinner';

const Assessment = props => {
  /* 
    invitation identifier can be one of 
    
    1. the actual id of an invitation
    2. an employe code
    3. a unique code issued by PIM
    4. an employees email
    5. a first name and last name

    We treat /:identifierIdentifer accordingly based on the validation configuration of the organisations portal

  */
  const { invitationIdentifier } = props;

  const {
    step,
    getInvitations,
    invitations,
    PARTICIPANT_VALIDATION_TYPE,
    configuration
  } = useAssessment();
  const branding = useSelector(state => state.Branding);

  const [thankYouPageTitle, setThankYouPageTitle] = useState('Thank You');
  const [thankYouPageParagrapah, setThankYouPageParagraph] = useState('');

  useEffect(() => {
    if (step !== ASSESSMENT_STEPS.RESULT && configuration) {
      setThankYouPageTitle(configuration.pimCompletedTitle);
      setThankYouPageParagraph(configuration.pimCompletedParagraph);
    }
  }, [step, configuration]);

  useEffect(() => {
    async function loadInvitation() {
      let payload = {
        subdomain: branding.brandedPortalSubdomain
      };

      switch (branding.brandedPortalParticipantValidation) {
        /*case PARTICIPANT_VALIDATION_TYPE.NAME_ONLY: {
          const parts = invitationIdentifier.split('%20');
          payload.firstName = parts[0];
          payload.lastName = parts[1];
          break;
        }*/
        case PARTICIPANT_VALIDATION_TYPE.INVITATION_LINK: {
          payload.id = invitationIdentifier;
          break;
        }
        case PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE: {
          payload.code = invitationIdentifier;
          break;
        }
        /*case PARTICIPANT_VALIDATION_TYPE.EMAIL_ANY: {
          // we need to ask the user to enter their email to receive a randomly generated code
          // we then use in conjunction with their name to return their invitations.
          payload.code = invitationIdentifier;
          break;
        }*/
        default:
          payload = undefined;
          break;
      }

      if (payload) {
        await getInvitations(payload);
      }
    }

    if (invitationIdentifier) {
      loadInvitation();
    }
  }, [invitationIdentifier]);

  if (!branding) {
    return null;
  }

  const renderStep = step => {
    if (step !== ASSESSMENT_STEPS.RESULT) {
      switch (branding.brandedPortalParticipantValidation) {
        case PARTICIPANT_VALIDATION_TYPE.INVITATION_LINK: {
          if (!invitationIdentifier || invitations?.length === 0) {
            return (
              <div className="flex text-center justify-center mt-4">
                <p>
                  Oops sorry. You seem to have an incorrect link.
                  <br /> Please contact your organisation.
                </p>
              </div>
            );
          } else if (!invitations) {
            return (
              <div className="flex items-center justify-center">
                <LoadingSpinner showLogo={false} />
              </div>
            );
          }
          break;
        }
        case PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE: {
          if (!invitationIdentifier) {
            return <EmployeeCode />;
          }
          break;
        }
        default:
          break;
      }
    }

    switch (step) {
      case ASSESSMENT_STEPS.EMAIL:
        return <Email />;
      case ASSESSMENT_STEPS.NAME:
        return <FirstNameLastName />;
      case ASSESSMENT_STEPS.CHOOSE_COLLEAGUES:
        return <ChooseColleagues />;
      case ASSESSMENT_STEPS.SELECT_INVITATION:
        return <SelectInvitation />;
      case ASSESSMENT_STEPS.PRE_INSIGHTS:
        return <PreInsights />;
      case ASSESSMENT_STEPS.RATE_ENERGIES:
        return <RateEnergies />;
      case ASSESSMENT_STEPS.COMPLETION_INSIGHTS:
        return <CompletionInsights />;
      case ASSESSMENT_STEPS.RESULT:
        return (
          <Result title={thankYouPageTitle} text={thankYouPageParagrapah} />
        );
      default:
        return <Introduction />;
    }
  };

  return <div className="mx-2">{renderStep(step)}</div>;
};

export default Assessment;
