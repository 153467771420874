import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import Vimeo from '@u-wave/react-vimeo';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import {
  isMobile,
  isTablet,
  browserName,
  browserVersion
} from 'react-device-detect';

const Introduction = () => {
  const {
    nextStep,
    explainerVideoUrl,
    storeMetrics,
    configuration,
    invitation
  } = useAssessment();

  const [deviceInfoRecorded, setDeviceInfoRecorded] = useState(false);

  useEffect(() => {
    async function saveDeviceInfo() {
      const { organisationId, assessmentId, submissionId } = invitation;
      await storeMetrics({
        metricDeviceType: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
        metricDeviceBrowser: `${browserName} ${browserVersion}`,
        organisationId,
        assessmentId,
        submissionId
      });
      setDeviceInfoRecorded(true);
    }
    if (invitation && !deviceInfoRecorded) {
      saveDeviceInfo();
    }
  }, [invitation]);

  const [loaded, setLoaded] = useState(false);
  if (!explainerVideoUrl?.trim().length > 0) {
    return (
      <div className="flex justify-center items-center">
        <LoadingSpinner showLogo={false} />
      </div>
    );
  }

  const onVideoStarted = async () => {
    if (invitation) {
      const { organisationId, assessmentId, submissionId } = invitation;
      await storeMetrics({
        metricVideoStarted: true,
        organisationId,
        assessmentId,
        submissionId
      });
    }
  };

  const onVideoProgress = async data => {
    if (invitation) {
      const { organisationId, assessmentId, submissionId } = invitation;
      await storeMetrics({
        metricVideoPercentPlayed: (data.percent * 100).toFixed(0),
        organisationId,
        assessmentId,
        submissionId
      });
    }
  };

  const onVideoCompleted = async () => {
    if (invitation) {
      const { organisationId, assessmentId, submissionId } = invitation;
      await storeMetrics({
        metricVideoCompleted: true,
        organisationId,
        assessmentId,
        submissionId
      });
    }
  };

  return (
    <div className="flex flex-col p-8 mt-4 text-md items-center text-center w-full">
      {configuration?.pimTitle ? (
        <h1 className="text-3xl text-bold mx-auto">
          {configuration?.pimTitle}
        </h1>
      ) : null}
      {configuration?.pimIntroText ? (
        <p className="mb-8 mt-2 text-sm">{configuration?.pimIntroText}</p>
      ) : null}

      <Vimeo
        video={explainerVideoUrl}
        autoplay={true}
        muted={false}
        controls={true}
        onProgress={onVideoProgress}
        onPlaying={onVideoStarted}
        onEnd={onVideoCompleted}
        width={isTablet || isMobile ? 400 : 800}
        onLoaded={() => setLoaded(true)}
      />

      {loaded ? (
        <div className="flex w-full justify-center mt-8">
          <Button
            onClick={nextStep}
            text="START My Positive Impact Questionnaire"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Introduction;
