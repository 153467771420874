import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { subscribeActionMiddleware } from 'redux-subscribe-action';
import { UserReducer, AssessmentReducer, BrandingReducer } from '../reducers';
import subscribe from './../subscribers';

const storeReducers = {
  User: UserReducer,
  Assessment: AssessmentReducer,
  Branding: BrandingReducer
};

const store = createStore(
  combineReducers(storeReducers),
  composeWithDevTools(applyMiddleware(thunk, subscribeActionMiddleware))
);

subscribe();
store.loaded = true;

export default store;
