import { BRANDING_ACTIONS } from '../../actions/branding';

const initialState = { loaded: false };

const BrandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANDING_ACTIONS.BRANDING_LOADED: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        customised: Object.keys(action.payload).length > 0
      };
    }

    default:
      return state;
  }
};

export default BrandingReducer;
