import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

const BooleanToggle = props => {
  const { dataPoint, onChange, minLabelWidth } = props;
  const [enabled, setEnabled] = useState(false);

  return (
    <Switch.Group as="div" className="flex items-center justify-start">
      <span className="flex flex-col" style={{ minWidth: minLabelWidth }}>
        <label
          htmlFor="email"
          style={{ minWidth: minLabelWidth }}
          className="block text-md text-left text-gray-900 mr-4">
          {dataPoint.label}
        </label>
        {/*<Switch.Description as="span" className="text-sm text-gray-500">
          Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
  </Switch.Description>*/}
      </span>
      <Switch
        checked={enabled}
        onChange={() => {
          setEnabled(!enabled);
          onChange(dataPoint, !enabled);
        }}
        className={clsx(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}>
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export default BooleanToggle;
