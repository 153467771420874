import React from 'react';
import versionInfo from './version.json';

const Version = props => {
  const { mode } = props;
  return (
    <>
      {mode !== 'sidebar' ? (
        <div className="text-white text-center text-xs mt-32">
          ({versionInfo.portalName}) @ Version {versionInfo.version} (
          {versionInfo.commit?.substring(0, 7)})
        </div>
      ) : (
        <div className="pt-4">
          <div className="text-white text-center text-xs mt-2 text-opacity-60 mr-6">
            v{versionInfo.version}
          </div>
          <div className="text-white text-center text-xs mt-2 text-opacity-60 mr-6">
            {versionInfo.commit?.substring(0, 7)}
          </div>
        </div>
      )}
    </>
  );
};

export default Version;
