import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { onSnapshot, doc } from 'firebase/firestore';

import { brandingLoadedAction } from '../../redux/actions/branding';
import { JOURNEYS } from '../../components/Assessment/Constants';
import { assessmentJourneyLoadedAction } from '../../redux/actions/assessment';

const propertyMap = {
  brandedPortalHeaderBgColor: '--color-header',
  brandedPortalButtonBgColor: '--color-button',
  brandedPortalButtonBgHoverColor: '--color-button-hover',
  brandedPortalButtonFontColor: '--color-button-text',
  brandedPortalButtonTextHoverColor: '--color-button-text-hover',
  brandedPortalButtonFontSize: '--font-size-button',
  brandedPortalBgColor: '--color-body'
};

const useBranding = () => {
  const dispatch = useDispatch();

  const branding = useSelector(state => state.Branding);

  const convertBrandedValue = (cssVariableName, value) => {
    if (cssVariableName.indexOf('--color') > -1) {
      const [r, g, b] = value.match(/\w\w/g).map(x => parseInt(x, 16));
      return `${r} ${g} ${b}`;
    } else if (cssVariableName.indexOf('--font-size') > -1) {
      return `${value}px`;
    }
    return value;
  };

  useEffect(() => {
    if (branding?.loaded && Object.keys(branding).length > 1) {
      Object.keys(propertyMap).forEach(key => {
        document.documentElement.style.setProperty(
          propertyMap[key],
          convertBrandedValue(propertyMap[key], branding[key])
        );
      });
    }
  }, [branding]);

  const watchBrand = async () => {
    const parts = window.location.hostname.split(['.']);

    if (
      parts.length < 3 ||
      parts[0] === 'www' ||
      parts[0] === 'positiveleadership'
    ) {
      dispatch(brandingLoadedAction({}));
      return;
    }

    return onSnapshot(doc(db, 'branding', parts[0]), doc => {
      const brandSetup = doc.data();
      dispatch(
        assessmentJourneyLoadedAction(
          JOURNEYS[brandSetup.brandedPortalParticipantValidation]
        )
      );
      dispatch(brandingLoadedAction(brandSetup));
    });
  };

  useEffect(() => {
    watchBrand();
  }, [window.location.hostname]);

  return {
    branding
  };
};

export default useBranding;
