import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const Radios = props => {
  const { dataPoint, onChange, minLabelWidth } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (dataPoint?.options) {
      setOptions(dataPoint.options.split(','));
    }
  }, [dataPoint]);

  return (
    <div
      className={clsx(
        'flex items-top',
        dataPoint.inlineLabel ? 'flex-row' : 'flex-col'
      )}>
      <label
        htmlFor="email"
        style={{ minWidth: minLabelWidth }}
        className="block text-md text-left text-gray-900 mr-4 mb-4">
        {dataPoint.label}
      </label>
      <div
        className={clsx(
          !isMobile ? 'justify-center' : '',
          'items-center',
          dataPoint.inlineLabel ? 'flex flex-col' : 'flex flex-row'
        )}>
        {options?.map((option, index) => (
          <div key={index} className="flex flex-row mr-2">
            <input
              id={dataPoint.name}
              name={dataPoint.name}
              type="radio"
              onChange={e => onChange(dataPoint, option)}
              className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2 mt-1"
              defaultChecked={dataPoint.defaultValue === option}
            />
            <label htmlFor="comments" className="text-gray-900 mr-2">
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radios;
