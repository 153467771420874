import React, { useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const EmployeeCode = () => {
  const { nextStep } = useAssessment();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [code, setCode] = useState('');

  const submitData = () => {
    if (!code || code.length === 0) {
      setError('Your invitation code is required.');
    } else {
      setSubmitted(true);
      nextStep({ code });
    }
  };

  const onEnter = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      submitData();
    }
  };

  document.addEventListener('keydown', onEnter);

  return (
    <div className="mt-3 flex flex-col w-full justify-center items-center">
      {submitted ? (
        <LoadingSpinner showLogo={false} />
      ) : (
        <>
          <div className="mb-4">
            Please enter the unique code provided by your organisation.
          </div>
          <input
            type="text"
            onChange={e => {
              setError();
              setCode(e.target.value);
            }}
            name="code"
            id="code"
            style={{ width: 240 }}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="Your Code"
          />

          <div className="mt-2 text-red">{error}</div>
          <Button onClick={submitData} text="CONTINUE" />
        </>
      )}
    </div>
  );
};

export default EmployeeCode;
