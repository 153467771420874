import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const Button = props => {
  const {
    text,
    style = {},
    path = '#',
    minWidth = 200,
    icon,
    fontNormal,
    onClick,
    disabled = false
  } = props;

  const buttonStyle = {
    ...style,
    minWidth
  };

  let classes = '';

  return (
    <Link
      to={path}
      type="button"
      style={buttonStyle}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={clsx(
        'my-4 text-center font-sans justify-center inline-flex items-center px-4 py-3 text-sm rounded-md shadow-sm',
        fontNormal ? '' : 'font-bold',
        classes
      )}>
      {icon ? <img src={icon} alt="" className="pr-2" width={30} /> : null}
      {text}
    </Link>
  );
};

export default Button;
