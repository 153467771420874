import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MobileNavigation from '../Navigation/MobileNavigation';
import CompanyLogo from '../../assets/company-logo.png';

const Header = () => {
  const [logo, setLogo] = useState(CompanyLogo);
  const branding = useSelector(state => state.Branding);

  useEffect(() => {
    if (branding?.brandedPortalLogo?.length > 0) {
      setLogo(branding.brandedPortalLogo[0].original);
    } else {
      setLogo(CompanyLogo);
    }
  }, [branding]);

  let [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0);
    }
    onScroll();
    window.addEventListener('scroll', onScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <header
      className={clsx(
        'bg-header sticky top-0 z-50 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-md shadow-slate-900/5 transition duration-500 dark:shadow-none sm:px-6 lg:px-8',
        isScrolled
          ? 'dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-plg-navy/75'
          : ''
      )}>
      <div className="mr-6 flex lg:hidden">
        <MobileNavigation />
      </div>
      <div
        className={clsx(
          'relative flex flex-grow basis-0',
          branding?.customised
            ? `justify-${branding.brandedPortalLogoPosition}`
            : 'justify-left'
        )}>
        <Link to="/" aria-label="Home page">
          <img className="w-16 lg:hidden" src={logo} alt="" />
          <img className="hidden h-9 w-auto lg:block" src={logo} alt="" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
