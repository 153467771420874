import React, { useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const FirstNameLastName = () => {
  const { nextStep } = useAssessment();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const submitData = () => {
    if (
      !firstName ||
      !lastName ||
      firstName.length === 0 ||
      lastName.length === 0
    ) {
      setError('Your first and last name are required');
    } else {
      setSubmitted(true);
      nextStep({ firstName, lastName });
    }
  };

  const onEnter = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      submitData();
    }
  };

  document.addEventListener('keydown', onEnter);

  return (
    <div className="mt-3 flex flex-col w-full justify-center items-center">
      {submitted ? (
        <LoadingSpinner showLogo={false} />
      ) : (
        <>
          <div className="mb-4">What is your first and last name?</div>
          <input
            type="text"
            onChange={e => {
              setError();
              setFirstName(e.target.value);
            }}
            name="firstName"
            id="firstName"
            style={{ width: 240 }}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="First Name"
          />
          <input
            type="text"
            onChange={e => {
              setError();
              setLastName(e.target.value);
            }}
            name="lastName"
            id="lastName"
            style={{ width: 240 }}
            className="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="Last Name"
          />
          <div className="mt-2 text-red">{error}</div>
          <Button onClick={submitData} text="CONTINUE" />
        </>
      )}
    </div>
  );
};

export default FirstNameLastName;
