import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import validator from 'validator';
import Button from '../../../Button';

const Email = () => {
  const { nextStep } = useAssessment();

  const [error, setError] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (email) {
      if (validator.isEmail(email)) {
        setError('');
      } else {
        setError('A valid email is required');
      }
    }
  }, [email]);

  return (
    <div className="mt-3 flex flex-col w-full justify-center items-center">
      <div className="mb-4">Enter your email address</div>
      <input
        type="email"
        onChange={e => setEmail(e.target.value)}
        name="email"
        id="email"
        style={{ width: 240 }}
        className=" shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        placeholder="i.e. jim.smith@Example.com"
      />
      <div className="mt-2 text-red">{error}</div>
      <Button
        onClick={e => {
          if (validator.isEmail(email || '')) {
            nextStep({ email });
          } else {
            setError('A valid email is required');
          }
        }}
        text="CONTINUE"
      />
    </div>
  );
};

export default Email;
