import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from '../views/404';
import ConfigurationError from '../views/ConfigurationError';
import Register from '../auth/Register';
import Login from '../auth/Login';

import Dashboard from '../views/Dashboard';
import FAQ from '../views/FAQ';
import Contact from '../views/Contact';
import MyProfile from '../views/MyProfile';
import VerificationEmailSent from '../auth/VerficiationEmailSent';
import FirebaseHandler from '../auth/Handler';
import ForgotPassword from '../auth/ForgotPassword';

const Routing = () => {
  const user = useSelector(state => state.User);

  const allowAnonymousLogin = true;

  return (
    <Routes>
      <Route path="/handler" element={<FirebaseHandler />} />
      {user?.validUser && !user.session.emailVerified ? (
        <>
          <Route path="*" element={<VerificationEmailSent />} />
        </>
      ) : user?.validUser || allowAnonymousLogin ? (
        <>
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/register" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/:invitationIdentifier" element={<Dashboard />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/error" element={<ConfigurationError />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/recover" element={<ForgotPassword />} />
        </>
      ) : (
        <>
          <Route path="/recover" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account-pending" element={<VerificationEmailSent />} />
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};
export default Routing;
