import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

const ChooseColleagues = () => {
  const { nextStep, invitation } = useAssessment();

  const { brandedPortalChooseColleagueCount } = useSelector(
    state => state.Branding
  );
  const [error, setError] = useState();
  const [selections, setSelections] = useState([]);
  const [entries, setEntries] = useState();

  useEffect(() => {
    async function load() {
      if (invitation?.submission?.entries) {
        setSelections([...invitation.submission.entries]);
        if (
          invitation.submission.entries.length <
          brandedPortalChooseColleagueCount
        ) {
          await nextStep([...invitation.submission.entries]); // prevents this step from loading
        }
      }
    }
    load();
  }, [invitation]);

  useEffect(() => {
    if (invitation) {
      setEntries([...invitation.submission.entries]);
    }
  }, [invitation]);

  const search = value => {
    if (!value || value.toString().trim().length === 0) {
      setEntries([...invitation.submission.entries]);
    } else {
      setEntries(
        [...invitation.submission.entries].filter(
          e =>
            `${e.firstName} ${e.lastName}`
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
        )
      );
    }
  };

  if (!entries) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center p-8 mt-8 text-md items-center text-center w-full">
      <p className="mb-4">Click the people you work with most often:</p>
      <div className="mb-8">
        <input
          onChange={e => search(e.target.value)}
          type="text"
          name="search"
          placeholder="Search by name"
          style={{ width: 300 }}
        />
      </div>
      <div className="flex flex-wrap">
        {entries.map((entry, index) => (
          <div
            key={index}
            onClick={() => {
              if (selections?.some(s => s.id === entry.id)) {
                setSelections([...selections.filter(s => s.id !== entry.id)]);
              } else {
                setSelections([...selections, entry]);
              }
            }}
            className={clsx(
              'm-1',
              selections?.some(s => s.id === entry.id)
                ? 'border-plg-green border-4'
                : 'border-plg-navy-100 border-1',
              'border-1 rounded-sm border inline-block cursor-pointer py-4 align-middle mb-1  bg-white flex flex-col text-center items-center'
            )}>
            <span style={{ fontSize: 18, paddingLeft: 10, paddingRight: 10 }}>
              {entry.firstName} {entry.lastName}
            </span>
            {selections?.some(s => s.id === entry.id) ? (
              <CheckCircleIcon width={24} height={24} color="green" />
            ) : (
              <XCircleIcon width={24} height={24} color="gray" />
            )}
          </div>
        ))}
      </div>

      <div className="mt-2 text-red" style={{ fontSize: 18, color: 'red' }}>
        {error}
      </div>
      <div className="flex w-full justify-center">
        {selections?.length > 0 ? (
          <Button
            onClick={() => {
              if (selections.length > 0) {
                nextStep(selections);
              } else {
                setError('At least one colleague must be chosen.');
              }
            }}
            text="Continue"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChooseColleagues;
