const PARTICIPANT_VALIDATION_TYPE = {
  NAME_ONLY: 'name',
  EMAIL_ANY: 'email_any',
  EMAIL_EMPLOYEE: 'email_employee',
  EMPLOYEE_CODE: 'code',
  INVITATION_LINK: 'invitation_link'
};

const ASSESSMENT_STEPS = {
  INTRODUCTION: 'INTRODUCTION',
  PRE_INSIGHTS: 'PRE_INSIGHTS',
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  CHOOSE_COLLEAGUES: 'CHOOSE',
  SELECT_INVITATION: 'SELECT_INVITATION',
  RATE_ENERGIES: 'RATE_ENERGIES',
  COMPLETION_INSIGHTS: 'COMPLETION_INSIGHTS',
  RESULT: 'RESULT'
};

const IDENTIFY_BY_INVITATION_ID_STEPS = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_EMPLOYEE_CODE_STEPS = [
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_NAME_STEPS = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.NAME,
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_ANY_EMAIL_STEPS = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.NAME,
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_EMPLOYEE_EMAIL_STEPS = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.NAME,
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const JOURNEYS = {};

JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.INVITATION_LINK
] = IDENTIFY_BY_INVITATION_ID_STEPS;
JOURNEYS[PARTICIPANT_VALIDATION_TYPE.NAME_ONLY] = IDENTIFY_BY_NAME_STEPS;
JOURNEYS[PARTICIPANT_VALIDATION_TYPE.EMAIL_ANY] = IDENTIFY_BY_ANY_EMAIL_STEPS;
JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.EMAIL_EMPLOYEE
] = IDENTIFY_BY_EMPLOYEE_EMAIL_STEPS;
JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE
] = IDENTIFY_BY_EMPLOYEE_CODE_STEPS;

export { ASSESSMENT_STEPS, JOURNEYS, PARTICIPANT_VALIDATION_TYPE };
