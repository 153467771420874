import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
//import Navigation from '../Navigation';
import { useSelector } from 'react-redux';

const Layout = props => {
  const {
    title,
    section /*sticky, stickyLeft, stickyBg = 'bg-white' */
  } = props;

  const branding = useSelector(state => state.Branding);

  return (
    <div
      className="min-h-screen flex-wrap h-screen"
      style={{ backgroundColor: branding?.brandedPortalBgColor }}>
      <Header />
      <div className="relative flex justify-center w-full">
        <div
          className="flex-auto"
          style={{ backgroundColor: branding?.brandedPortalBgColor }}>
          <article className="pt-4">
            {(title || section) && (
              <header className="mb-9 space-y-1">
                {section && (
                  <p className="font-display text-sm font-medium text-sky-500">
                    {section}
                  </p>
                )}
                {title && (
                  <h1 className="font-display text-3xl tracking-tight text-slate-900 dark:text-white">
                    {title}
                  </h1>
                )}
              </header>
            )}
            {props.children}
          </article>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
