import React, { useEffect, useState } from 'react';

const Checkboxes = props => {
  const { dataPoint, onChange, minLabelWidth } = props;

  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (dataPoint?.options) {
      setOptions(dataPoint.options.split(','));
    }
    if (dataPoint?.defaultValue) {
      setSelectedItems([dataPoint.defaultValue]);
    }
  }, [dataPoint]);

  useEffect(() => {
    if (selectedItems && dataPoint) {
      onChange(dataPoint, selectedItems.join(','));
    }
  }, [selectedItems]);

  const onItemChecked = (option, checked) => {
    const index = selectedItems.findIndex(o => o === option);
    const exists = index > -1;
    if (checked) {
      if (!exists) {
        setSelectedItems([...selectedItems, option]);
      }
    } else if (exists) {
      const items = [...selectedItems];
      items.splice(index, 1);
      setSelectedItems(items);
    }
  };

  return (
    <div className="flex flex-row items-top">
      <label
        htmlFor="email"
        style={{ minWidth: minLabelWidth }}
        className="block text-md text-left text-gray-900 mr-4">
        {dataPoint.label}
      </label>
      <div className="space-y-5">
        {options?.map((option, index) => (
          <div key={index} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={dataPoint.name}
                name={dataPoint.name}
                type="checkbox"
                onChange={e => onItemChecked(option, e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                defaultChecked={dataPoint.defaultValue === option}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                {option}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkboxes;
