import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ASSESSMENT_STEPS,
  PARTICIPANT_VALIDATION_TYPE
} from '../../components/Assessment/Constants';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

import {
  invitationsLoadedAction,
  invitationsSelectedAction,
  submissionEntriesFinalisedAction,
  nextStepAction,
  previousStepAction,
  colleaguesSelectedAction,
  entriesCompletedAction
} from '../../redux/actions/assessment';

/*
    Hook which provides services to present the energy ratings questions
*/
const useAssessment = () => {
  const dispatch = useDispatch();
  const functions = getFunctions();

  const [invitation, setInvitation] = useState();
  const [loaded, setLoaded] = useState(false);
  const [entry, setEntry] = useState();
  const {
    invitations,
    selectedInvitationIndex,
    step,
    entryIndex,
    journey,
    completed
  } = useSelector(state => state.Assessment);

  const {
    brandedPortalParticipantValidation,
    brandedPortalExplainerVideoUrl,
    brandedPortalSubdomain
  } = useSelector(state => state.Branding);

  useEffect(() => {
    if (!invitations || invitations.length === 0 || !invitation) {
      setEntry(undefined);
    } else {
      setEntry(
        invitation.submission.entries.filter(e => !e.notRequired)[entryIndex]
      );
    }
  }, [entryIndex, invitation]);

  useEffect(() => {
    if (
      selectedInvitationIndex === -1 ||
      !invitations ||
      invitations?.length === 0
    ) {
      setInvitation(undefined);
    } else {
      setInvitation(invitations[selectedInvitationIndex]);
    }
    setLoaded(true);
  }, [selectedInvitationIndex]);

  useEffect(() => {
    async function saveEntries() {
      await finaliseEntries(invitation);
    }
    if (completed && invitation) {
      saveEntries();
    }
  }, [completed]);

  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const getInvitations = async data => {
    const plgInvitationsFunction = httpsCallable(
      functions,
      'plgGetMyInvitations'
    );
    const result = await plgInvitationsFunction(data);
    dispatch(invitationsLoadedAction(result.data));
  };

  const storeMetrics = async data => {
    const plgMetricsFunction = httpsCallable(
      functions,
      'plgStoreParticipantMetrics'
    );
    await plgMetricsFunction(data);
  };

  const finaliseEntries = async () => {
    const plgSubmissionFunction = httpsCallable(
      functions,
      'plgSubmitParticipantEntries'
    );
    const result = await plgSubmissionFunction(
      invitations[selectedInvitationIndex]
    );
    dispatch(submissionEntriesFinalisedAction(result));
  };

  const saveDataPointValue = async (dataPointId, value) => {
    const plgDataPointFunction = httpsCallable(
      functions,
      'plgSaveInsightDataPoint'
    );
    await plgDataPointFunction({
      organisationId: invitation.organisationId,
      assessmentId: invitation.assessmentId,
      submissionId: invitation.submissionId,
      insightConfigurationId: invitation.configuration.insights.id,
      dataPointId,
      value
    });
  };

  const selectInvitation = async invitation => {
    dispatch(invitationsSelectedAction(invitation));
  };

  const selectColleagues = async entries => {
    dispatch(colleaguesSelectedAction(entries));
  };

  const nextStep = async data => {
    if (
      step === ASSESSMENT_STEPS.INTRODUCTION &&
      brandedPortalParticipantValidation ===
        PARTICIPANT_VALIDATION_TYPE.INVITATION_LINK
    ) {
      await selectInvitation(invitations[0].id);
    } else if (
      step === ASSESSMENT_STEPS.EMAIL ||
      step === ASSESSMENT_STEPS.NAME
    ) {
      await getInvitations({
        ...data,
        subdomain: brandedPortalSubdomain
      });
    } else if (step === ASSESSMENT_STEPS.SELECT_INVITATION) {
      await selectInvitation(data);
    } else if (step === ASSESSMENT_STEPS.RATE_ENERGIES) {
      dispatch(entriesCompletedAction());
    } else if (step === ASSESSMENT_STEPS.CHOOSE_COLLEAGUES) {
      await selectColleagues(data);
    }
    dispatch(nextStepAction(data));
  };

  const previousStep = async () => {
    dispatch(previousStepAction());
  };

  return {
    getInvitations,
    selectInvitation,
    finaliseEntries,
    storeMetrics,
    nextStep,
    previousStep,
    step,
    journey,
    invitation,
    loaded,
    entry,
    invitations,
    explainerVideoUrl: brandedPortalExplainerVideoUrl,
    configuration: invitation?.configuration?.assessment,
    PARTICIPANT_VALIDATION_TYPE,
    saveDataPointValue
  };
};

export default useAssessment;
