import React, { useEffect, useState } from 'react';
import useAssessment from '../../../hooks/assessment';
import { ASSESSMENT_STEPS } from '../Constants';
import { BooleanToggle, Dropdown, Radios, Textarea, Textbox } from './Fields';
import Checkboxes from './Fields/Checkboxes';
import Button from '../../Button';
import { isMobileOnly } from 'react-device-detect';

/*
    Renders the correct insights based on the current step and assessment configuration
*/
const Insights = props => {
  const { onNoActionRequired } = props;

  const {
    invitation,
    loaded,
    step,
    nextStep,
    saveDataPointValue
  } = useAssessment();

  const [dataPoints, setDataPoints] = useState([]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    if (
      !invitation ||
      !invitation?.configuration?.insights ||
      invitation?.configuration?.insights?.dataPoints?.length === 0 ||
      (step === ASSESSMENT_STEPS.PRE_INSIGHTS &&
        invitation?.configuration?.insights?.dataPoints.filter(
          d => d.askOn === 'start'
        ).length === 0) ||
      (step === ASSESSMENT_STEPS.COMPLETION_INSIGHTS &&
        invitation?.configuration?.insights?.dataPoints.filter(
          d => d.askOn === 'end'
        ).length === 0)
    ) {
      if (onNoActionRequired) {
        nextStep();
      }
      return;
    }

    if (step && invitation?.configuration?.insights?.dataPoints?.length > 0) {
      let askOn = '';
      switch (step) {
        case ASSESSMENT_STEPS.PRE_INSIGHTS: {
          askOn = 'start';
          break;
        }
        case ASSESSMENT_STEPS.RATE_ENERGIES: {
          askOn = 'each';
          break;
        }
        case ASSESSMENT_STEPS.COMPLETION_INSIGHTS: {
          askOn = 'end';
          break;
        }
        default:
          break;
      }

      if (askOn.length > 0) {
        setDataPoints(
          invitation?.configuration?.insights?.dataPoints
            .filter(d => d.askOn === askOn && !d.hidden)
            .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
        );
      }
    }
  }, [loaded, invitation]);

  const onDataInputProvided = async (dataPoint, value) => {
    await saveDataPointValue(dataPoint.id, value);
  };

  const minLabelWidth = isMobileOnly ? '100%' : 200;
  const minInputWidth = isMobileOnly ? '100%' : 300;

  return (
    <div className="flex flex-col justify-center text-center mt-4 items-center mx-4">
      <p className="text-center mb-8">
        Please help us by providing the following information:
      </p>
      <div className="mx-auto space-y-5">
        {dataPoints?.map((dataPoint, index) => {
          switch (dataPoint.displayAs) {
            case 'textarea':
              return (
                <div key={index} className="my-2">
                  <Textarea
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div key={index} className="my-4">
                  <Dropdown
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
            case 'radio':
              return (
                <div key={index} className="my-2">
                  <Radios
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <div key={index} className="my-2">
                  <Checkboxes
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
            case 'toggle':
              return (
                <div key={index} className="my-2">
                  <BooleanToggle
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
            default:
              return (
                <div key={index} className="my-2">
                  <Textbox
                    dataPoint={dataPoint}
                    onChange={onDataInputProvided}
                    minLabelWidth={minLabelWidth}
                    minInputWidth={minInputWidth}
                  />
                </div>
              );
          }
        })}
      </div>
      <div className="flex w-full justify-center mt-8">
        <Button onClick={nextStep} text="CONTINUE" />
      </div>
    </div>
  );
};

export default Insights;
