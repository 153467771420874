import React from 'react';
import Layout from '../../scaffolding/Layout';
import Assessment from '../../components/Assessment';
import { useParams } from 'react-router-dom';

const Dashboard = () => {
  const params = useParams();

  return (
    <Layout>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 items-center lg:mx-0 lg:max-w-none lg:grid-cols-1 mb-4">
        <Assessment invitationIdentifier={params?.invitationIdentifier} />
      </div>
    </Layout>
  );
};

export default Dashboard;
