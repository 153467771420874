import React, { useEffect } from 'react';

import Routing from '../routing';
import { onSnapshot, doc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { db, auth } from './../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import useUser from '../hooks/user';
import useBranding from '../hooks/branding';
import LoadingSpinner from '../utils/LoadingSpinner';

const Scaffolding = () => {
  const [user] = useAuthState(auth);

  const { recordLastLogin } = useUser();
  const dispatch = useDispatch();
  const { branding } = useBranding();

  useEffect(() => {
    let unsubWatchUser = () => {};
    async function load() {
      if (user) {
        await recordLastLogin(user.uid);

        unsubWatchUser = onSnapshot(doc(db, 'users', user.uid), doc => {
          let userDoc = doc.data();

          if (!['owner', 'admin'].some(r => r === userDoc.role)) {
            dispatch({
              type: 'USER_LOADED',
              payload: { ...userDoc, session: user } || {
                error: `could not find user with uid ${user.uid}`
              }
            });
          } else {
            dispatch({
              type: 'INVALID_USER',
              payload: {}
            });
            signOut(auth)
              .then(() => {
                // Sign-out successful.
              })
              .catch(error => {
                console.log(error);
              });
          }
        });
      } else {
        dispatch({
          type: 'USER_LOGOUT',
          payload: {}
        });
        signOut(auth)
          .then(() => {
            // Sign-out successful.
          })
          .catch(error => {
            console.log(error);
          });

        return;
      }
    }
    load();

    return unsubWatchUser;
  }, [user]);

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: branding?.brandedPortalBgColor
          ? branding.brandedPortalBgColor
          : 'transparent'
      }}>
      {branding?.loaded ? (
        <Routing branding={branding} />
      ) : (
        <div className="flex justify-center items-center">
          <LoadingSpinner showLogo={true} />
        </div>
      )}
    </div>
  );
};

export default Scaffolding;
