import clsx from 'clsx';
import React from 'react';

const Textbox = props => {
  const { dataPoint, onChange, minLabelWidth, minInputWidth } = props;
  return (
    <div
      className={clsx(
        'flex items-center',
        dataPoint?.labelInline ? 'flex-row' : 'flex-col'
      )}>
      <label
        htmlFor="email"
        style={{ minWidth: minLabelWidth }}
        className="block text-md text-left text-gray-900 mr-4">
        {dataPoint.label}
      </label>
      <div className="mt-2">
        <input
          style={{ minWidth: minInputWidth }}
          type="text"
          onChange={e => onChange(dataPoint, e.target.value)}
          defaultValue={dataPoint.defaultValue}
          name={dataPoint.name}
          id={dataPoint.id}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={dataPoint.placeholder}
        />
      </div>
    </div>
  );
};

export default Textbox;
