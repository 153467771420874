import React, { useEffect } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const SelectInvitation = () => {
  const { nextStep, invitations } = useAssessment();

  useEffect(() => {
    if (invitations?.length === 1) {
      nextStep(invitations[0]);
    }
  }, [invitations]);

  if (invitations?.length === 1) {
    return null;
  }

  if (!invitations) {
    return (
      <div className="flex flex-col justify-center p-8 mt-8 text-md text-center w-full">
        <LoadingSpinner showLogo={false} />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center p-8 mt-8 text-md text-center w-full">
      {invitations?.length === 0 ? (
        <span>You have no active invitations.</span>
      ) : (
        <div>
          <span>Please choose your organisation</span>
          <div className="flex w-full justify-center">
            {invitations?.map((invitation, index) => (
              <Button
                key={index}
                onClick={() => nextStep(invitation)}
                text={invitation.organisationName}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectInvitation;
