import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//import CompanyLogo from '../../assets/company-logo.png';

const Footer = () => {
  const [logo, setLogo] = useState();
  const branding = useSelector(state => state.Branding);

  useEffect(() => {
    if (branding?.brandedPortalFooterLogo?.length > 0) {
      setLogo(branding.brandedPortalFooterLogo[0].original);
    } else {
      //setLogo(CompanyLogo);
    }
  }, [branding]);

  return (
    <footer
      className="sticky max-auto top-[100vh] mb-5 flex flex-col items-center text-center justify-center"
      style={{ backgroundColor: branding.brandedPortalBgColor }}>
      {logo ? <img className="h-8" src={logo} alt="" /> : null}
      <p className="pt-4 text-sm px-8">
        {branding?.brandedPortalFooterText ||
          'Copyright © Positive Leadership Global. All Rights Reserved.'}
      </p>
      <a
        className="cursor-pointer text-sm pt-2"
        href={
          branding.customised &&
          branding?.brandedPortalPrivacyUrl?.trim().length > 0
            ? branding?.brandedPortalPrivacyUrl
            : 'https://positiveleadership.global/privacy-policy/'
        }>
        Privacy Policy
      </a>
    </footer>
  );
};

export default Footer;
